<template>
	<svg xmlns="http://www.w3.org/2000/svg" v-bind="$attrs" viewBox="0 0 790.52292 730.49185">
		<path
			class="w-primary--darken4"
			d="M252.65186,143.094a47.96791,47.96791,0,0,0-47.91332,47.91332V742.99707a47.96778,47.96778,0,0,0,47.91332,47.91272H657.57652a47.96766,47.96766,0,0,0,47.91272-47.91272V191.00728A47.96778,47.96778,0,0,0,657.57652,143.094Z"
			transform="translate(-204.73854 -84.75407)"
		/>
		<rect x="24.91599" y="92.37149" width="450.91871" height="579.75263" fill="#fff" />
		<rect class="w-secondary--darken2" x="83.86359" y="196.28941" width="333.02352" height="21.87746" />
		<rect class="w-secondary--darken2" x="83.86359" y="254.6293" width="333.02352" height="21.87746" />
		<rect class="w-secondary--darken2" x="83.86359" y="312.96919" width="333.02352" height="21.87746" />
		<rect class="w-secondary--darken2" x="83.86359" y="371.30907" width="333.02352" height="21.87746" />
		<rect class="w-secondary--darken2" x="83.86359" y="429.64896" width="333.02352" height="21.87746" />
		<rect class="w-secondary--darken2" x="83.86359" y="487.98885" width="333.02352" height="21.87746" />
		<rect class="w-secondary--darken2" x="83.86359" y="546.32874" width="333.02352" height="21.87746" />
		<rect x="332.19662" y="167.05547" width="335.70794" height="431.62449" transform="translate(-265.49105 11.51607) rotate(-10.37053)" fill="#e6e6e6" />
		<rect
			class="w-secondary--darken2"
			x="352.62694"
			y="246.5507"
			width="247.93524"
			height="16.28772"
			transform="translate(-242.80136 5.19986) rotate(-10.37053)"
		/>
		<rect
			class="w-secondary--darken2"
			x="360.44561"
			y="289.27509"
			width="247.93524"
			height="16.28772"
			transform="translate(-250.36459 7.30526) rotate(-10.37053)"
		/>
		<rect
			class="w-secondary--darken2"
			x="368.26429"
			y="331.99947"
			width="247.93524"
			height="16.28772"
			transform="translate(-257.92782 9.41067) rotate(-10.37053)"
		/>
		<rect
			class="w-secondary--darken2"
			x="376.08296"
			y="374.72385"
			width="247.93524"
			height="16.28772"
			transform="translate(-265.49105 11.51607) rotate(-10.37053)"
		/>
		<rect
			class="w-secondary--darken2"
			x="383.90164"
			y="417.44824"
			width="247.93524"
			height="16.28772"
			transform="matrix(0.98366, -0.18001, 0.18001, 0.98366, -273.05427, 13.62147)"
		/>
		<rect
			class="w-secondary--darken2"
			x="391.72032"
			y="460.17262"
			width="247.93524"
			height="16.28772"
			transform="translate(-280.6175 15.72687) rotate(-10.37053)"
		/>
		<rect
			class="w-secondary--darken2"
			x="399.53899"
			y="502.89701"
			width="247.93524"
			height="16.28772"
			transform="translate(-288.18073 17.83227) rotate(-10.37053)"
		/>
		<rect x="776.82937" y="612.36811" width="94.98996" height="122.12995" transform="translate(-312.49937 74.63623) rotate(-10.37053)" fill="#e6e6e6" />
		<rect x="782.61021" y="634.86162" width="70.15431" height="4.60868" transform="translate(-306.07923 72.84904) rotate(-10.37053)" fill="#fff" />
		<rect x="784.82254" y="646.95066" width="70.15431" height="4.60868" transform="translate(-308.21928 73.44477) rotate(-10.37053)" fill="#fff" />
		<rect x="787.03487" y="659.0397" width="70.15431" height="4.60868" transform="translate(-310.35932 74.0405) rotate(-10.37053)" fill="#fff" />
		<rect x="789.2472" y="671.12874" width="70.15431" height="4.60868" transform="translate(-312.49937 74.63623) rotate(-10.37053)" fill="#fff" />
		<rect x="791.45952" y="683.21779" width="70.15431" height="4.60868" transform="translate(-314.63942 75.23197) rotate(-10.37053)" fill="#fff" />
		<rect x="793.67185" y="695.30683" width="70.15431" height="4.60868" transform="translate(-316.77946 75.8277) rotate(-10.37053)" fill="#fff" />
		<rect x="795.88418" y="707.39587" width="70.15431" height="4.60868" transform="translate(-318.91951 76.42343) rotate(-10.37053)" fill="#fff" />
		<path
			class="w-primary--darken4"
			d="M538.60427,115.13943H498.86881v-8.13468a22.25068,22.25068,0,0,0-22.25068-22.25068H433.60965A22.25067,22.25067,0,0,0,411.359,107.00475v8.13468H371.62352a22.25067,22.25067,0,0,0-22.25067,22.25068V199.003H560.85494V137.39011A22.25067,22.25067,0,0,0,538.60427,115.13943Z"
			transform="translate(-204.73854 -84.75407)"
		/>
		<path
			d="M440.85016,714.719c6.5698-4.49614,13.32206-9.17218,21.12382-11.21592,7.11815-1.86465,15.29169-1.37138,20.92177,3.84062,4.54356,4.20617,6.46192,10.671,3.09554,16.20474a10.29789,10.29789,0,0,1-6.82246,4.96408,8.21862,8.21862,0,0,1-7.38872-2.88661c-1.7651-1.95045-2.82116-4.83323-1.88322-7.40419.98627-2.70345,3.62463-4.3568,6.21589-5.27948a46.00407,46.00407,0,0,1,9.27017-1.915l12.89241-1.76494,52.76891-7.22392,58.76538-8.04482a1.83592,1.83592,0,0,0,1.27334-2.24265,1.87009,1.87009,0,0,0-2.24265-1.27334L491.90922,706.48508c-4.2295.579-8.51865.99359-12.70418,1.84428a21.363,21.363,0,0,0-9.12184,3.7888c-5.60138,4.43473-5.20124,12.27858-.04661,16.918,5.21814,4.69652,12.78382,3.888,17.36291-1.29921a15.74343,15.74343,0,0,0,1.19385-19.291c-4.99863-7.53725-14.10711-10.43294-22.81019-9.36395-10.06518,1.23628-18.59831,6.89386-26.77333,12.48856-1.92477,1.31724-.103,4.47836,1.84033,3.14842Z"
			transform="translate(-204.73854 -84.75407)"
			fill="#3f3d56"
		/>
		<path
			d="M851.14474,579.59059l1.21542,12.15415s-7.29249,26.73911,8.5079,26.73911,3.64624-26.73911,3.64624-26.73911l1.21542-9.72332Z"
			transform="translate(-204.73854 -84.75407)"
			fill="#a0616a"
		/>
		<path
			d="M961.54032,539.0976,955.96349,549.965s-20.63925,18.49815-7.36938,27.075,17.577-20.47743,17.577-20.47743l6.29883-7.50632Z"
			transform="translate(-204.73854 -84.75407)"
			fill="#a0616a"
		/>
		<path
			d="M870.59137,590.52932l17.0158,115.46436,8.5079,82.64818s7.29249,18.23121,31.60078,2.43083l-3.64625-47.40116-2.43083-42.5395s-2.43082-41.32409-4.86165-48.61658,1.21541-31.60077,1.21541-31.60077l9.72332-40.10867Z"
			transform="translate(-204.73854 -84.75407)"
			fill="#2f2e41"
		/>
		<path
			d="M898.5459,786.211l-9.72331,10.93873s-14.585-4.86166-18.23122,4.86165,27.95453,12.15415,27.95453,12.15415,32.81619,2.43083,32.81619,0-.8793-21.02177-5.30131-22.05732S898.5459,786.211,898.5459,786.211Z"
			transform="translate(-204.73854 -84.75407)"
			fill="#2f2e41"
		/>
		<path
			d="M916.77712,584.45225l17.0158,121.54143,8.5079,82.64818s7.29248,18.23121,31.60077,2.43083l-3.64624-47.40116-2.43083-42.5395s-2.43083-41.32409-4.86166-48.61658,1.21542-31.60077,1.21542-31.60077l-2.43083-44.97033Z"
			transform="translate(-204.73854 -84.75407)"
			fill="#2f2e41"
		/>
		<path
			d="M944.73165,786.211l-9.72332,10.93873s-14.585-4.86166-18.23121,4.86165,27.95453,12.15415,27.95453,12.15415,32.81618,2.43083,32.81618,0-.8793-21.02177-5.3013-22.05732S944.73165,786.211,944.73165,786.211Z"
			transform="translate(-204.73854 -84.75407)"
			fill="#2f2e41"
		/>
		<circle cx="675.57615" cy="282.13901" r="25.5237" fill="#a0616a" />
		<polygon points="694.415 293.685 705.354 311.917 677.399 324.071 673.753 302.193 694.415 293.685" fill="#a0616a" />
		<path
			d="M913.13087,394.84762s-25.5237-2.43083-35.247,13.36955L853.89409,426.4494a13.82858,13.82858,0,0,0-5.34159,12.82422L869.376,596.60639l30.38536-1.21541,49.832-9.72332,14.585-3.64624-7.29249-64.417s-8.5079-25.5237,1.21541-42.5395l-4.86165-59.5553L919.208,402.1401Z"
			transform="translate(-204.73854 -84.75407)"
			fill="#575a89"
		/>
		<path
			d="M856.0064,431.31005l-1.82312-3.03854s-2.43083,0-2.43083,4.86166-4.25395,109.995-4.25395,109.995l-1.21541,40.10868,23.09287,2.43082,8.5079-75.35568-3.64624-49.832Z"
			transform="translate(-204.73854 -84.75407)"
			fill="#575a89"
		/>
		<path
			d="M936.22375,413.07883l17.0158,2.43083a112.94944,112.94944,0,0,1,17.0158,23.09287c7.29248,13.36956,29.16994,38.89326,24.30828,58.33989S975.117,551.63606,975.117,551.63606l-23.09288-9.72331s18.23122-42.5395,17.0158-46.18574-15.80038-25.52371-15.80038-25.52371Z"
			transform="translate(-204.73854 -84.75407)"
			fill="#575a89"
		/>
		<path
			d="M862.69118,352.91582s-20.662-1.21541-7.29249-14.585c0,0,10.93873-4.86166,15.80039-2.43083,0,0,7.29249-4.86166,7.29249-2.43083,0,0,25.5237-7.29248,29.16994,18.23122s-2.43083,37.67784-2.43083,37.67784l-17.0158-1.21541s3.64624-20.66205-9.72331-18.23122c0,0-10.93873,3.64625-12.15415,0S862.69118,352.91582,862.69118,352.91582Z"
			transform="translate(-204.73854 -84.75407)"
			fill="#2f2e41"
		/>
		<polygon points="667.676 499.09 678.615 426.166 671.322 381.195 673.753 430.109 666.461 499.09 667.676 499.09" opacity="0.2" />
	</svg>
</template>

<script>
export default {
	name: 'UndefinedContractImage'
}
</script>

<style scoped>
.w-primary--darken4 {
	fill: var(--v-primary-darken4);
}

.w-secondary--darken2 {
	fill: var(--v-secondary-darken2);
}
</style>
